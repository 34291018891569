import React, { Component } from 'react';
import Advantage from './advantage';


class AdvantageList extends Component {
    state = {  } 
    render() { 
        return (<React.Fragment>
            <div className="row row-cols-md-4">
              
              <Advantage src="" title="Small and Portable" 
              text="" 
              alt="" 
              footerTitle=""
              footer="" />

              <Advantage src="" 
              title="Affordable and durable" 
              text='' 
              alt="" 
              footerTitle=""
              footer="" />
              
              <Advantage src="" 
              title="Ease of Use" text="" alt="" footer="" />
             
          </div>
        </React.Fragment>);
    }
}
 
export default AdvantageList;