import React, { Component } from 'react';


class Footer extends React.Component {
    render() { 
        return <footer class="container" 
        style={{paddingTop:"3px",paddingLeft:"2px",paddingRight:"2px"}}>
        <div class="grid">
          <div class="row">
            <div class="col-md" style={{textAlign:"center"}}>
              <img height="30px" 
              src="https://pbs.twimg.com/profile_images/1569668016295624709/81GmFidJ_400x400.jpg"/> 
            </div>

            <div class="col-md" style={{textAlign:"center"}}>
              <img height="30px" 
              src="https://www.amrc.co.uk/img/logo-header-2020.png?1601374585"/> 
            </div>

            <div class="col-md" style={{textAlign:"center"}}>

              <img height="30px" 
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d4/Sheffield_Hallam_University_logo.svg/375px-Sheffield_Hallam_University_logo.svg.png"/> 
            </div>

            <div class="col-md" style={{textAlign:"center"}}> 
              <img height="30px" 
              src="http://weareteamsy.org/wp-content/uploads/2021/12/dmc_iot_engine-logo-full-color-rgb.jpg"/> 
            </div>
          
            <div class="col-md" style={{textAlign:"center"}}>
              <img height="30px" 
              src="https://iforgesheffield.org/wp-content/uploads/2022/08/FuturaLogo.png"/> 
            </div>

            
            <div class="col-md" style={{textAlign:"center"}}>
             <img height="30px" 
              src="https://www.homecare.co.uk/assets/images/theme/logo.svg"/> 
            </div>
          </div>
        </div>
            
        
      </footer>;
    }
}
 
export default Footer;