import './App.css';
import React, { useState, useEffect }  from 'react';
import NavigationBar from './Components/navigationbar';
import Home from './Views/home';
import News from './Views/news';
import About from './Views/about';
import Contact from './Views/contact';
import Why from './Views/why';


import WebFont from 'webfontloader';

import {
  BrowserRouter as Router,
  Routes, Route

} from 'react-router-dom';
import Footer from './Components/footer';

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Droid Sans', 'Chilanka','Work Sans','Gentium Plus',
            'Dancing Script','Permanent Marker','PT Serif','Potta One']
      }
    });
   }, []);

  return (
    <div>
        <Router>
            <NavigationBar/>
            <Routes>
              <Route  path="About" element={<About/>} />
              <Route  path="News" element={<News/>} />
              <Route  path="Contact" element={<Contact/>} />
              <Route  path="Why" element={<Why/>} />
              <Route  path="/*" element={<Home/>} />
              <Route  path="" element={<Home/>} />
             
            </Routes>
            <Footer/>
          </Router>

    </div>
  );
}

export default App;
