import React, { Component } from 'react';
import './news.css';
import NewsItem from './news_item';



class News extends Component {
    render() {
        return <div className='container'>
               
        <h3>In News</h3> 

         
         <br/><br/>

         <NewsItem className="news-font"
                src="https://shop.wedo3dprinting.com/pub/media/logo/default/miniNewcolour_Logo.png"  
                news="Foot Rocka devices first patch handed to Active Legs by WeDo 3 Design with the aim to launch it soon.
                "
                date="8 Sept 2022"
                mimage="/images/wedo.jpeg" 
                link="https://twitter.com/adamswahili3/status/1546913745569472519" 
                linktext="Foot Rocka prototype's design has finally been concluded by Darren Baker of WeDo 3D Printing Sheffield company"
                /> 

       <NewsItem className="news-font"
                src="https://cdnapisec.kaltura.com/p/2103181/sp/210318100/thumbnail/entry_id/0_xdqrdsrh/version/100091/width/382/height/213/type/3/quality/100"  
                news="Held review meeting with with Peter Mylon of iForge Dept of Sheffield University worked in collaboration with Active Legs Ltd. The Digital Health Rocka aims to support people with limited mobility. Equally the portable foot-rocka users could benefit from real-time health metrics.
                "
                date="12 Aug 2022"
                mimage="/images/mylon.jpeg" 
                link="https://twitter.com/adamswahili3/status/1558174761959686157s" 
                linktext=" Peter Mylon of iForge Dept of Sheffield University worked in collaboration with Active Legs Ltd."
                /> 

      <NewsItem className="news-font"
                src="https://www.franksco.com/images/logo.png"  
                news="Finally, the various types of Foot Rocka devices design protection have been filled by Dr Abdulmalik Lawal Patent and Trade Mark attorney of Frank's and Co Ltd based in Sheffield.
                "
                date="12 Aug 2022"
                mimage="https://pbs.twimg.com/media/FZWPC-RXoAYuCsr?format=jpg&name=900x900" 
                link="https://twitter.com/adamswahili3/status/1555306535982612480" 
                linktext="Foot Rocka devices design protection have been filed"
                />        

         <NewsItem className="news-font"
                src="/images/freepress.jpeg"  
                news="A project that has stood out for me is Active Legs, a digital health 
                start-up by the University that develops leg exercise devices for people with 
                limited mobility. I was in charge of developing the electronics and software for this
                 device, a portable foot-rocker so that users could benefit from real-time health metrics."
                date="31 Jun 2022"
                mimage="" 
                link="https://www.freepressjournal.in/education/indian-student-in-the-uk-makes-the-most-of-his-study-abroad-journey" 
                linktext="Indian student in the UK makes the most of his study abroad journey"
                />    

  
         <NewsItem className="news-font"
                src="https://www.amrc.co.uk/img/logo-header-2020.png?1601374585"  
                news="Dustin Burns 15 Jun 2021 Design engineers at the University of Sheffield 
                Advanced Manufacturing Research Centre (AMRC) have supported a Sheffield
                 businessman to develop his foot and leg exercise device, and explore
                  options for taking it into production."
                date="15 Jun 2021"
                link="https://www.amrc.co.uk/news/exercise-device-rocking-on-with-amrc" 
                linktext="Exercise device rocking on with AMRC - Machinery"
                />    
    <br/>
    <br/>
    
                 <NewsItem 
                src="https://www.amrc.co.uk/img/logo-header-2020.png?1601374585"  
                news="The idea for Active Legs came to me while squashed in economy class for seven 
                and a half hours, unable to do any kind of exercise,” said Adam. 
                “I wanted to create a way of exercising the legs and feet using a small, 
                portable device people could take with them on an aeroplane, where weight and space 
                is at a premium."
                date="10 Jun 2021"
                link="https://m4snews.com/en/News/144/44513/Exercise-device-rocking-on-with-AMRC" 
                linktext="Exercise device rocking on with AMRC"
                />            
        

<br/><br/>
      <NewsItem 
                src="https://www.eurekamagazine.co.uk/media/xx3ogc3h/eureka-logo-black.png"  
                news="As well as helping Yusuf develop his concept and build the prototype, 
                the AMRC has also supported Adam in exploring further potential markets such as
                 the elderly and people with mobility challenges, including those undergoing 
                 physiotherapy or rehabilitation. The device is currently awaiting a semi
                  clinical trial with Sheffield Hallam University.."
                date="09 Jun 2021"
                link="https://www.eurekamagazine.co.uk/content/news/amrc-gives-legs-to-exercise-prototype" 
                linktext="Exercise device rocking on with AMRC"
                />    


    </div>

        
    }
}
 
export default News;