import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AdvantageList from './advantage-list';
class Section1 extends React.Component {
    
    //255 ,169 , 58
    render() { 
        return <div>
            
           <div className="row">
                <div className="col-md-6" style={{textAlign:"center"}}>
                    <img src="images/Pic05.png" width="200px"/><br/>
                    <img src="images/Pic06.png" width="200px"/>  <br/>
                    <img src="images/Pic07.png" width="200px"/>  <br/>
                    <img src="images/Pic08.png" width="200px"/>  <br/>
                </div>
                <div className="col-md-6">
                    <img src="images/hand.jpg" width="200px"/> <br/>
                    <img src="images/hand2.jpg" width="200px"/>  <br/>
                    <img src="images/bag.jpg" width="200p"/>  <br/>
                    <img src="images/stacked.jpg" width="200px"/>  <br/>

                </div>

           </div>
      </div>;
    }
}
 
export default Section1;