import React, { Component } from 'react';



class CarouselItem extends Component {
    render() {
        return <div class="carousel-item active">
        <div class="row d-flex justify-content-center">
          <div class="col-md-8 col-lg-9 col-xl-8">
            <div class="d-flex">
              <div class="flex-shrink-0">
                <img src="https://m.media-amazon.com/images/P/B08VRNQ2T9.01._SCLZZZZZZZ_SX500_.jpg"
                  alt="woman avatar" width="90"
                  />

{/* class="rounded-circle mb-4 mb-lg-0 shadow-2"  */}
              </div>
              <div class="flex-grow-1 ms-4 ps-3">
                <figure>
                  <blockquote class="blockquote mb-4">
                    <p>
                      <i class="fas fa-quote-left fa-lg text-warning me-2"></i>
                      <span class="font-italic">The human foot is a masterpiece of engineering
With 26 bones, 33 joints, and over 100 muscles, tendons and ligaments, our feet are like the Swiss army knife of the body: beautifully designed, compact and multifunctional. They have relevance to almost everything we do, but how often do we stop to truly appreciate their full wonder?</span>
                    </p>
                  </blockquote>
                  <figcaption class="blockquote-footer">
                  JAMES EARLS, Director of Born to Move, <cite title="Source Title">Understanding the Human Foot</cite>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
        
    }
}
 
export default CarouselItem;