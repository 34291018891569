import React, { Component } from 'react';



class CarouselItem2 extends Component {
    render() {
        return <div class="carousel-item active">
        <div class="row d-flex justify-content-center">
          <div class="col-md-8 col-lg-9 col-xl-8">
            <div class="d-flex">
              <div class="flex-shrink-0">
                <img src="https://m.media-amazon.com/images/P/B08VRNQ2T9.01._SCLZZZZZZZ_SX500_.jpg"
                  alt="woman avatar" width="90"
                  />

{/* class="rounded-circle mb-4 mb-lg-0 shadow-2"  */}
              </div>
              <div class="flex-grow-1 ms-4 ps-3">
                <figure>
                  <blockquote class="blockquote mb-4">
                    <p>
                      <i class="fas fa-quote-left fa-lg text-warning me-2"></i>
                      <span class="font-italic">77% of US Adult Americans experience foot problems, according to a 2014 survey by 
                      the American Podiatric Medical Association (APMA). 
                      The poll also showed that 83% have regular foot pain and 51% Adult have actvities restrcited by foot pain. With top being Walking and Standing for long time.</span>
                    </p>
                  </blockquote>
                  <figcaption class="blockquote-footer">
                  American Podiatric Medical Association (APMA)<cite title="Source Title">Public Opinion Research on Foot Health and Care Survey of 1000 US Adults
March 2014</cite>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
        
    }
}
 
export default CarouselItem2;