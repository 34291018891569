import React, { Component } from 'react';

import './home.css';

class Section0 extends React.Component {
    
    //255 ,169 , 58
    render() { 
        return <div style={{verticalAlign:"top"}}>
                     <h2 className="head-font-home" style={{textAlign:"center",fontSize:"x-large"}}>
                     Active Legs presents Pocket Rocka a new innovation
                      </h2>
                      <h3 className="head-font-home" style={{textAlign:"center",fontSize:"medium"}}>
                      Foot Rocker so slim and light, can fit in pocket!
                      </h3>
                       

                    <div className="section0-img" style={{color:"gray"}}>

                        <div className="handimg" style={{textAlign:"right"}}>
                            <div>
                                <img width="160vw" style={{maxHeight:"20vw"}}
                                src="images/hand.jpg"/>
                                <p style={{fontSize:"2vw"}}> </p>
                            </div>

                            <div>
                                <img width="80vw"  style={{maxHeight:"20vw"}}
                                src="images/bag.jpg"/>
                                <p style={{fontSize:"1vw"}}> </p>
                            </div>
                        </div>
                        
                        <img className="centerimg" src="images/stacked.jpg"/>
                        <div className="centered">
                            <p style={{fontSize:"2vw"}}>Revolutionary Foot Rocker</p> <br/>
                        </div>
                        <div className="centered2" style={{fontSize:"1vw"}}>
                            <span>Portable</span> <br/> 
                            <span>Light</span> <br/>
                            <span>Affordable</span> 
                        </div>
                            
                        <div className="centered3" style={{fontSize:"1vw"}}>
                            <span>Stretches and strengthens
                                muscles, ligaments and tendons</span> <br/> 
                            <span>Increases the blood circulation</span> <br/>
                            <span>Helps stimulate calf muscles</span> 
                        </div>    

                        
                       
                        
                        <div className="row leftlogo" style={{width:"90%"}}>
                            <div className="col col-md-4 col-lg-3 col-sm-3">
                                <a href="https://www.amrc.co.uk/img/logo-header-2020.png?1601374585">
                                    <div >
                                    <img 
                                    width="65%"
                                    style={{maxHeight:"55%",maxWidth:"90px"}}
                                    src="https://www.sheffield.ac.uk/themes/custom/uos/images/logos/uos-crest.svg"/>
                                    <p style={{fontSize:"1vw"}}>Designed in collobration with AMRC</p>
                                    </div>    
                                </a>
                            </div>
                        </div>    
                        <div className="row leftlogo2">
                        <div className="col col-lg-8 col-sm-2">
                                <a href="https://www.eurekamagazine.co.uk/content/news/amrc-gives-legs-to-exercise-prototype">
                                <img width="25%"
                                src="https://d1l6hqpjksdq9d.cloudfront.net/Prod/af611462-ca90-dadf-f0e8-d4cc25129f91"/>
                                <p style={{fontSize:"1vw"}}>Awaiting a semi clinical trial with Sheffield Hallam  
                                Hallam </p>
                                </a>
                            </div>
                            <div className="col-lg-10">&nbsp;</div>
                        </div>

                       

                </div>
                
                      
                
            </div>;
    }
}
 
export default Section0;