import React, { Component } from 'react';
import './news.css';



class NewsItem extends Component {
    render() {
        return  <div style={{borderTop:"solid black 2px",width:"100%",padding:"0px",margin:"0px"}}>
            <div className="row">
                <div className="col col-md-2" >
                    <img src= {this.props.src} style={{width:"100%"}}/>
                </div>
                <div className="col col-md-10 news-font" style={{textAlign:"justify"}}>
                
                 {this.props.news}
                 <img src= {this.props.mimage} style={{width:"25%",textAlign:"center"}}/>
                </div>
            </div>
            
            <div className="row">
               <hr/>
            </div>
            <div className="row">
                <div className="col col-md-2">
                   <i> {this.props.date} </i>
                </div>
                <div className="col col-md-10">
                    <a href={this.props.link}>{this.props.linktext}</a> 
                    
                </div>
                <hr/>
            </div>
            
        </div>
        

    }
}
 
export default NewsItem;