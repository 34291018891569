import React, { Component } from 'react';
import FeatureList from './feature-list';


class Section2 extends React.Component {
    
    //255 ,169 , 58
    render() { 
        return <div>
                <h2 className="font_2" 
                style={{lineHeight:"1.5em", fontSize:"45px",fontFamily:"Gentium Plus"}}>
                    FEATURED IN</h2>
                <FeatureList/>

      </div>;
    }
}
 
export default Section2;