import React, { Component } from 'react';
import { Link,useLocation,NavLink  } from 'react-router-dom';
import PropTypes from 'prop-types';
import './nav.css';
import { Nav, Navbar} from 'react-bootstrap';


class NavigationBar extends Component {
    
    
    styles = {
        
    }

    MenuIcon = (props) =>(
      <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} 
      className="navbar-nav-svg" 
      viewBox={props.viewBox} role="img">
        <title>{props.title}</title>
        <path fill={props.fill} d={props.d}></path>
      </svg>
  )

      
    render() { 
        return <React.Fragment>
         
        <div className="container" style={{borderColor:"black",borderStyle:"solid",borderWidth:"3px",padding:"0px"}}>
        <header className="navbar navbar-expand-lg  sticky-top" style={{padding:"0px",margin:"0px"}}>
        <Navbar className="container" aria-label="Main navigation">
        
  
          <Navbar.Brand href="/" class="brand">
              <img src='images/logo.png' style={{width:"40px",padding:"0px",margin:"5px"}}/>
              <span style={{fontWeight:"BOLD",margin:"5px"}}>ACTIVE LEGS</span>
          </Navbar.Brand>
      
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="flex-row flex-wrap ms-md-auto topnav">
              <NavLink   to="/">Home</NavLink>
              <NavLink  to="/why">Why</NavLink>
              <NavLink  to="/News">News</NavLink>
              <NavLink  to="About">About</NavLink>
              <NavLink  to="Contact">Contact</NavLink>
            </Nav>
        </Navbar.Collapse>
      
     
        </Navbar>
      </header>
       <nav className="container flex-wrap flex-md-nowrap" style={{backgroundColor:"white",padding:"0px"}} aria-label="Secondary">
        <ul className="navbar-nav flex-row flex-wrap bd-navbar-nav navbar-left" style={{textAlign:"left"}}>
              <li className="nav-item col--sm-2 col-sm-auto" >
                <a className="nav-link p-2" href="https://twitter.com/adamswahili3" >
                 <this.MenuIcon viewBox="0 0 512 416.32" 
                  title="Twitter"
                  fill="black" 
                  width="20"
                  height="20"
                  
                  d="M160.83 416.32c193.2 0 298.92-160.22 298.92-298.92 0-4.51 0-9-.2-13.52A214 214 0 0 0 512 49.38a212.93 212.93 0 0 1-60.44 16.6 105.7 105.7 0 0 0 46.3-58.19 209 209 0 0 1-66.79 25.37 105.09 105.09 0 0 0-181.73 71.91 116.12 116.12 0 0 0 2.66 24c-87.28-4.3-164.73-46.3-216.56-109.82A105.48 105.48 0 0 0 68 159.6a106.27 106.27 0 0 1-47.53-13.11v1.43a105.28 105.28 0 0 0 84.21 103.06 105.67 105.67 0 0 1-47.33 1.84 105.06 105.06 0 0 0 98.14 72.94A210.72 210.72 0 0 1 25 370.84a202.17 202.17 0 0 1-25-1.43 298.85 298.85 0 0 0 160.83 46.92"  
                 ></this.MenuIcon>
                </a>
              </li>
              <li className="nav-item col--sm-2 col-sm-auto" >
                <a className="nav-link p-2" href="https://www.facebook.com/adam.yusuf.520" >
               
                <this.MenuIcon viewBox="0 0 16 16"
                  title="Twitter"
                  fill="black" 
                  width="20"
                  height="20"
                  d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"  
                 ></this.MenuIcon>
               
                </a>
              </li>

              <li className="nav-item col-2 col-sm-auto" >
                <a className="nav-link p-2" href="https://www.linkedin.com/in/adam-yusuf-91749626/?originalSubdomain=uk">

                <this.MenuIcon viewBox="0 0 16 16"
                  title="Twitter"
                  fill="black" 
                  width="20"
                  height="20"
                  d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"  
                 ></this.MenuIcon>
                </a>
              </li>
            </ul>
        </nav> 
        </div>
        </React.Fragment>
    }
}
 
export default NavigationBar;