import React, { Component } from 'react';
import Section1 from './section1';
import Section0 from './section0';
import Section2 from './section2';


class Home extends Component {
    render() {
        return <div  className="container">
            <Section0/>
            <br/><br/>
            {/* <div style={{height:"2px",width:"100%",borderColor:"black",backgroundColor:"#000000"}}/> */}
            {/* <Section1/> */}
        </div>
        
    }
}
 
export default Home;