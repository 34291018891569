import React, { Component } from 'react';
import './about.css';


class News extends Component {
    render() {
        return <div  className="container" style={{backgroundColor:"lightgray"}}>
            <div className="row">
                <div className="col-md-8">
                    <br/>
                <h4 className= "head-font" style={{paddingLeft:"30px",fontSize:"30px"}}>
                    Meet Adam Who Started It All</h4> 
                    <p className= "body-font" style={{paddingLeft:"30px",textAlign:"justify"}}> 
                    Adam Yusuf from Somaliland came to UK in 1986 and lived in London for a year and later on moved to
Sheffield in 1987 and build his life in Sheffield . He began a career in Housing Department with Sheffield
City Council, and studied Housing Studies at Sheffield Hallam University, and this role lasted for seven
years. Afterwards he continued to work in the voluntary and private sectors for about ten years and was
able to set up various organisations and companies that delivered programmes in the community such
as transport training, child care and re-settlement programmes for the newly arrived asylum seekers.

                    <br/> <br/>
                    Presently Adam Yusuf is the chair of Israac Somali Community Association a charity organisation based
in Shefffield since 2013 and plays an active role in the voluntary sector shaping the development of the
organisation as well as bringing new ideas, skills, knowledge , network and enriching the diversity of
Sheffield in various capacity. 

                  
                    </p>
                </div>
                 <div className="col-md-4" style={{paddingLeft:"10px",paddingTop:"5px",
                        paddingRight:"0px",paddingBottom:"10px"}}>
                    <img src="/images/adam.jpeg"
                    width="90%" />
                </div>

            </div>
            <div className="row">
            <div className="col-md-12">
            <p className= "body-font" style={{paddingLeft:"30px",
                paddingRight:"30px",textAlign:"justify"}}>
              Adam started a new venture known as <b>Active Legs Ltd</b> and worked on three new types of innovative
                devices and they are; <br/><b>
                1) Pocket-Rocka  &nbsp; &nbsp;&nbsp;2) Flat Rocka &nbsp;&nbsp;and  &nbsp;&nbsp;
                3) Digital health Rocka </b><br/>
                which are recognized as new innovation in field of health and welll being sector. and the new product's will hopefully be
                launched at the end of October 2022. Finally, during the period of the product development both Sheffield
                Univesity and Sheffield Hallam University have worked in collaboartive with Active Legs to enhance the
                foot rocka devices further in capacity.  
                </p>
          
                <p className= "body-font" style={{paddingLeft:"30px",
                paddingRight:"30px",textAlign:"justify"}}>
                <b style={{fontSize:"22pt"}}>O</b>ther organisations and companies that have supported Active Legs over the period in terms of advice,
                expertise and support are as follows:<br/>
                <ol type="1">
                <li>Israac Somali Community Association -  
                    <a href="https://linkedin.com/in/geoff-green-04959618"> Prof Geoff Green</a></li>
                <li>Devices for Dignity (D4D) - <a href="https://linkedin.com/in/lise-sproson-025a54a1">
                Lise Sproson (Manager)</a></li>
                <li>Chairobics excercise expert - 
                <a href="https://linkedin.com/in/barbara-sharp-12582982">
                Barbara Sharp (Trainer)</a>
                </li>
                <li>Agency for Culture and Change Management - 
                <a href="https://linkedin.com/in/ibrahim-nelson-kaggwa-8827a124">
                    Dr Nelson Kaggwa (Director)
                    </a>
                </li>
                <li>Hallam Homecare Services - Waseem Althulaya (Director)
                </li>
                <li>Sheffield City Region (SCR) - 
                <a href="https://linkedin.com/in/nigel-trant-14316812">
                    Nigel Trant (Innovation Advisor) </a>
                </li>
                <li>  <a href="https://linkedin.com/in/irshad-akbar-39b15820a">Irshad Akbar</a> - Mentor
                </li>
                <li>Sheffield University Iforge Department - 
                <a href="https://linkedin.com/in/peter-mylon-9422bb99">
                    Peter Mylon (Academic Lead for iForge)
                    </a>
                </li>
                <li>Advanced Manufacturing Research Centre (AMRC) -
                <a href="https://linkedin.com/in/marcus-crossley-8130b461">
                     Marcus Crossley</a> Senior Project
                Manager Design Prototyping Group / Medical AMRC
                
                </li>
                <li>Sheffield Hallam University Health &amp; Wellbeing - 
                    <a href="https://www.abdn.ac.uk/iahs/research/profiles/c.m.bond">Christine Bond </a> (Business Dev Mananger)
                </li>
                <li>IoT Engine Programme DMC Barnsley - 
                    <a href="http://linkedin.com/in/lauren-shale-215ab4201">Lauren Shale</a> Programme Coordinator
                    
                </li>
                <li>IoT Engine Programme DMC Barnsley - 
                    <a href="http://linkedin.com/in/rikki-coles-7027181a">Dr Rikki Coles </a>Senior Technical Lead
                </li>
                </ol>
                                </p>
                </div>
            </div>
           
             
        </div>
        
    }
}
 
export default News;