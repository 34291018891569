import React, { Component } from 'react';


class Advantage extends Component {
    state = {  } 
    render() { 
        return (<React.Fragment>
        = <div className="col">
            <div className="card h-100">
              <img src={this.props.src} className="card-img-top" style={{margin:"15px",width:"80%",textAlign:"center"}} alt="..."/>
              <div className="card-body">
                <h5 className="card-title" style={{textAlign:"center"}}>{this.props.title}</h5>
                <p className="card-text">{this.props.text}</p>
              </div>
              <div className="card-footer">
                  <a href={this.props.footer}> {this.props.footerTitle} </a>
              </div>
            </div>
          </div>
 
        </React.Fragment>);
    }
}
 
export default Advantage;