import React, { Component } from 'react';



class Contact extends Component {
    render() {
        return <div  className="container">
            <h1>Contact Us</h1> <br/>
            <div className="row">
                <div className="col col-md-6">
                <form id="contact-form" 
                  
                        method="POST">
                    <div className="form-group">
                        <input type="text" placeholder='Name*' className="form-control" />
                    </div>
                    <br/>
                    <div className="form-group">
                        <input type="email" placeholder='Email*' className="form-control" aria-describedby="emailHelp" />
                    </div>
                    <br/>
                    <div className="form-group">
                        <input type="phone" placeholder='Phone*' className="form-control" aria-describedby="emailHelp" />
                    </div>
                    <br/>
                    <div className="form-group">
                        <textarea placeholder='Message*' className="form-control" rows="5"></textarea>
                    </div>
                    <br/>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
                </div>
                <div className="col col-md-4">
                 Active Legs Ltd. <br/>
                 Sheffield    <br/><br/>
                 Telephone: 07957888400 <br/><br/>
                 Email: activelegs2021@gmail.com
                    
                </div>
            </div>
            
        </div>
        
    }
}
 
export default Contact;