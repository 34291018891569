import React, { Component } from 'react';
import Featured from './featured';


class FeatureList extends Component {
    state = {  } 
    render() { 
        return (<React.Fragment>
            <div className="row row-cols-1 row-cols-md-3">

               
              <Featured src="/images/uos-crest.svg" title="University of Sheffield" 
              text="Design engineers at the University of Sheffield Advanced Manufacturing Research Centre (AMRC) have supported a Sheffield businessman to develop his foot and leg exercise device, and explore options for taking it into production." 
              alt="" 
              footerTitle="Active Legs prototype..."
              footer="https://www.amrc.co.uk/news/exercise-device-rocking-on-with-amrc" />

              <Featured src="/images/eureka-logo-black.png" 
              title="Exercise prototype" 
              text='Sheffield local businessman build a prototype to aid with leg exercises' 
              alt="" 
              footerTitle="Sheffield local business builds a prototype..."
              footer="" />
              
              <Featured src="/images/uos-crest.svg" title="" text="" alt="" footer="" />
             
          </div>
        </React.Fragment>);
    }
}
 
export default FeatureList;