import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import References from './references';
import { faQuoteRight,faQuoteLeft } from '@fortawesome/free-solid-svg-icons'



class Why extends Component {
    render() {
        return <div  className="container">
             <br/>
            <h3>Why care for foot?</h3>
            <br/>
            <h5><u>Healthy feet are the foundation of a healthy body</u></h5>
            <figure  
            style={{borderRadius: "15px",backgroundColor:"white"}}>
            <blockquote class="blockquote" className="text-center mb-0">
               <p className="lead font-italic pb-3" style={{color:"black"}}>
                <FontAwesomeIcon icon={faQuoteLeft} className="text-primary fa-xs"/>
                Your feet must last a lifetime, and most Americans log
                 an amazing 75,000 miles on their feet by the time they reach age 50. 
                 Regular foot care can make sure your feet are up to the task. With proper detection, 
                 intervention, and care, most foot and ankle problems can be lessened or prevented.
                 <FontAwesomeIcon icon={faQuoteRight}  className="text-primary fa-xs"/>
                </p>
            </blockquote>
            <figcaption className="blockquote-footer mb-0 text-primary" 
            style={{fontWeight:"BOLD",fontSize:"14px"}}>
            American Podiatric Medical Association (APMA)
            </figcaption>
            </figure>



            <figure  
            style={{borderRadius: "15px",backgroundColor:"white"}}>
            <blockquote class="blockquote" className="text-center mb-0">
               <p className="lead font-italic pb-3" style={{color:"black"}}>
                <FontAwesomeIcon icon={faQuoteLeft} className="text-primary fa-xs"/>
                The human foot is a masterpiece of engineering
With 26 bones, 33 joints, and over 100 muscles, tendons and ligaments, our feet are like the 
Swiss army knife of the body: beautifully designed, compact and multifunctional. They have
 relevance to almost everything we do, but how often do we stop to truly appreciate their
  full wonder?
                 <FontAwesomeIcon icon={faQuoteRight}  className="text-primary fa-xs"/>
                </p>
            </blockquote>
            <figcaption className="blockquote-footer mb-0 text-primary" 
            style={{fontWeight:"BOLD",fontSize:"14px"}}>
             JAMES EARLS, Director of Born to Move, <cite title="Source Title">Understanding the Human Foot</cite>
            </figcaption>
            </figure>


            <figure  
            style={{borderRadius: "15px",backgroundColor:"white"}}>
            <blockquote class="blockquote" className="text-center mb-0">
               <p className="lead font-italic pb-3" style={{color:"black"}}>
                <FontAwesomeIcon icon={faQuoteLeft} className="text-primary fa-xs"/>
                77% of US Adult Americans experience foot problems, according to a 2014 survey by 
                      the American Podiatric Medical Association (APMA). 
                      The poll also showed that 83% have regular foot pain and 51% Adult have actvities restrcited by foot pain with top being Walking and Standing for long time.
                 <FontAwesomeIcon icon={faQuoteRight}  className="text-primary fa-xs"/>
                </p>
            </blockquote>
            <figcaption className="blockquote-footer mb-0 text-primary" 
            style={{fontWeight:"BOLD",fontSize:"14px"}}>
            Public Opinion Research on Foot Health and Care Survey of 1000 US Adults March 2014 </figcaption>
            </figure>

           
            {/* <References/>
            */}
        </div>
        
    }
}
 
export default Why;